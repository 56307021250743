import './Terms.css';

function Terms() {

    return (
        <div className="terms">
            <div className="terms-header">
                <h1>Legal terms</h1>
                <div className="line" />
            </div>
            <div className="terms-content">
                <p>
                    BUI STUDIOS<br />
                    Represented by:<br />
                    Hai Dang Bui<br />
                    Reinickendorfer Strasse 22<br />
                    13347 Berlin, Germany<br />
                    Phone: +49 176 432 977 46<br />
                    E-Mail: info@buistudios.com<br />
                </p>
                <p><span>COPYRIGHT AND OWNERSHIP</span></p>
                <p>The contents of our pages were created with the utmost care. However, we cannot
                    guarantee the accuracy, completeness and timeliness of the content. As a service provider,
                    we are responsible for our own content on these pages under the general laws according
                    to § 7 paragraph 1 TMG. According to §§ 8 to 10 TMG, however, we are not obligated as a
                    service provider to monitor transmitted or stored third-party information or to investigate
                    circumstances that indicate illegal activity. Obligations to remove or block the use of
                    information under the general laws remain unaffected. However, liability in this respect is</p>
                <p><span>LIABILIT FOR LINKS TO THIRD PARTY CONTENTS</span></p>
                <p>Our offer contains links to external websites of third parties, on whose contents we have no
                    influence. Therefore, we cannot assume any liability for these external contents. The
                    respective provider or operator of the pages is always responsible for the content of the
                    linked pages. The linked pages were checked for possible legal violations at the time of
                    linking. Illegal contents were not recognizable at the time of linking. However, a permanent
                    control of the contents of the linked pages is not reasonable without concrete evidence of</p>
                <p><span>COPYRIGHT LAW</span></p>
                <p>The content and works created by the site operators on these pages are subject to German
                    copyright law. The reproduction, editing, distribution and any kind of exploitation outside
                    the limits of copyright require the written consent of the respective author or creator.
                    Downloads and copies of this site are only permitted for private, non-commercial use.
                    Insofar as the content on this site was not created by the operator, the copyrights of third
                    parties are respected. In particular, third-party content is identified as such. Should you</p>
                <p><span>DATA PRIVACY</span></p>
                <p>The use of our website is generally possible without providing personal data. As far as on
                    our sides personal data (such as name, address or e-mail addresses) are collected, this is
                    as far as possible on a voluntary basis. We point out that data transmission over the
                    Internet (eg communication by e-mail) security gaps. The use of published postal
                    addresses, telephone or fax numbers and email addresses for marketing purposes is
                    prohibited, offenders sending unwanted spam messages will be punished. The operators
                    of the pages expressly reserve the right to take legal action in the event of the unsolicited
                    sending of advertising information, such as spam e-mails.</p>
                <p><span>GOOGLE ANALYTICS</span></p>
                <p>This website uses Google Analytics, a web analytics service provided by Google, Inc
                    (''Google''). Google Analytics uses so-called ''cookies'', which are text files placed on your
                    computer, to help the website analyze how users use the site. The information generated
                    by the cookie about your use of the website (including your IP address) will be transmitted
                    to and stored by Google on servers in the United States. Google will use this information
                    for the purpose of evaluating your use of the website, compiling reports on website activity
                    for website operators and providing other services relating to website activity and internet
                    usage. Google may also transfer this information to third parties where required to do so by
                    law, or where such third parties process the information on Google's behalf. Google will
                    not associate your IP address with any other data held by Google. You may refuse the use
                    of cookies by selecting the appropriate settings on your browser, however please note that
                    if you do this you may not be able to use the full functionality of this website. By using this
                    website, you consent to the processing of data about you by Google in the manner and for
                    the purposes set out above.</p>
            </div>
        </div >
    );
}

export default Terms;