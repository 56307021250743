import './Logo.css';
import { useEffect, useState } from 'react'

function Logo() {
    let [color, setColor] = useState("black");

    useEffect(() => {
        let path = window.location.pathname;
        if (path === "/" || path === "/nonconformity") {
            setColor("white");
        }
    }, [])

    return (
        <div className="logo">
            <a
                href='/'
                style={{ color: color }}
            >BUI.</a>
        </div>
    );
}

export default Logo;