import './Nonconformity.css';


function Nonconformity() {
    return (
        <div className="nonconformity">
            <div className="nonconformity-background">
                <div className="nonconformity-heading">
                    <h2>NOVEMBER 2022</h2>
                    <h1>NONCONFORMITY</h1>
                    <h3>(IN OR AFTER A WHILE)</h3>
                </div>
            </div>
        </div>
    );
}

export default Nonconformity;