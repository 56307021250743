import './Info.css';

function Info() {

    return (
        <div className="info">
            <div className='info-header'>
                <h1>General Info</h1>
                <div className="line" />
            </div>
            <div className="info-content">
                <p>Our service team is always happy to answer questions about your orders, so please feel
                    free to contact them with your questions:
                    customer@buistudios.com</p>
                <p><span>SHIPPING POLICY</span></p>
                <p>Please allow 5 working days for order fulfillment notice and tracking information.
                    Be aware that because our items are made to order in berlin, you might have to wait up to
                    14 working days for delivery.
                    For domestic orders inside Germany we offer an express tracked delivery which should
                    arrive in 2-3 working days
                    For international customers based outside Germany, we offer express courier shipping
                    which should arrive in 3-8 working days.
                    Free shipping worldwide on all orders (over 480€)
                    You will get an order confirmation email aÿer your order is processed. Please give yourself
                    5 working days to receive your tracking information email during busy periods.
                    Shipping time for pre-orders is mentioned in the product description.
                    Please make sure you provide the correct address, taking into account whether you are at
                    work or have someone at home sign for you.
                    Some packages will require a signature on delivery.
                    You will receive your personal tracking number when your order has been packaged and
                    sent so that you can check on the status of your delivery.
                    The email you provided at the checkout will be used to send this.
                    Taxes, duties, border charges, or duty reimbursement on any international orders are not
                    the responsibility of bui studios.
                    Before making a purchase, check your country's border charges.</p>
                <p><span>Returns</span></p>
                <p>To ensure proper sizing, we strongly recommend consulting our size charts prior to placing
                    your order.
                    We are keen to reduce returns as they are not very environmentally friendly - so please be
                    mindful of what and how much you order.
                    For hygienic reasons, classified shapewear garments cannot be returned or exchanged.
                    Orders of personalized or customized goods cannot be returned or exchanged.
                    Please contact our studio at customer@buistudios.com, for any requests on additional
                    customizations.
                    The only exception is if the item is flawed or does not match the description. We hope you
                    like wearing it as much as we enjoy creating them.</p>
                <p><span>FAULTY ITEMS</span></p>
                <p>Please contact us via customer@buistudios.com with photos of the fault and your order
                    number if you think your purchased item is faulty, incorrect, or missing an item. Any claims
                    must be lodged within 14 days of item receipt. Only if a proven defect is found in the item
                    will a refund be processed. In the event that a return does not meet our standards, we
                    reserve the right to refuse it. When trying on items, please be cautious as stained/dirty
                    items will not be accepted.</p>
                <p><span>EXCHANGES</span></p>
                <p>We accept returns and exchanges for items that are not physically damaged. For return/
                    exchange information, please contact customer@buistudios.com with your order number.
                    Changes in size are dependent on availability. The customer is responsible for paying any
                    shipping charges associated with exchanges and returns due to "change of mind." Bui
                    does not pay shipping fees for returns or exchanges unless proven faulty. Only once the
                    item is received back in our studio in original condition, with original packaging, returns,
                    refunds or exchanges will be processed.</p>
            </div>
        </div >
    );
}

export default Info;