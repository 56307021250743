import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './About.css';
import background1 from "./bg_about.png";

function About() {
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    return (
        <div className="about">
            <div className="about-heading-background" style={{
                backgroundImage: `url(${background1})`
            }}
                data-aos={"fade-up"}
                data-aos-duration="500"
            >
                <div
                    className="about-heading"
                    data-aos={"zoom-in"}
                >
                    <h1>ABOUT</h1>
                    <h2>BUI.</h2>
                    <h3>(THINGS MADE BY PEOPLE)</h3>
                </div>
                <p
                    data-aos={"zoom-in"}
                >(CONCEPT)</p>
            </div>
            <div className="about-page1">
                <div
                    className="about-page1-heading1"
                    data-aos={"zoom-in"}
                >
                    <h1><span>BUI.</span></h1>
                    <h1>IS A CREATIVE FASHION STUDIO</h1>
                    <h1>PROVIDING NEW PERSPECTIVES IN A CONSTANTLY GROWING INDUSTRY</h1>
                    <p>We design <span>bespoke luxury fashion</span> artefacts, disconnected from seasonal demands, created for special occasions with the highest appreciation of craftsmanship and quality embracing the notion of fashion as a from of art</p>
                </div>
                <div
                    className="about-page1-text1"
                    data-aos={"zoom-in"}
                >
                    <h2>CREATE FASHION TO WORK BEYOND FASHION</h2>
                    <p>WE ARE AMBITIOUS TO BUILD AND SHAPE A CULTURE<br />
                        BY WORKING WITH SKILLED ARTISANS IN PARTNERSHIPS OF MANY VERTICALS,<br />
                        IGNORING COMMERCIAL DEMANDS AND RETURN TO TRUE CRAFTSMANSHIP AND WHAT IS MOST LOVED</p>
                    <h2>ART ON ANOTHER CANVAS</h2>
                    <p>SHARE THE COMMON AND EMBRACE THE DIFFERENT</p>
                </div>
            </div>
            <div className="about-page2">
                <div
                    className="about-page2-heading"
                    data-aos={"zoom-in"}
                >
                    <p>(DECONSTRUCT AND REIMAGINE RUNNING SYSTEMS)</p>
                    <h2>
                        AS A <span>MULTIDISCIPLINARY FASHION STUDIO</span> WE SERVE THE PEOPLE
                        <br />
                        WHO SEEK A CHANGE IN THE INDUSTRY
                    </h2>
                    <h3>DRAWING ON THE CRITICAL GAZE TO JUSTIFY A GROSS GENRE ESTABLISHED <span>AVANT-GARDE</span> MOVEMENT</h3>
                </div>
                <div
                    className="about-page2-text"
                    data-aos={"zoom-in"}
                >
                    <p>
                        WITH AN FUNDAMENTAL UNDERSTANDING IN DESIGN AND TECHNOLOGY
                        <br />
                        RESEARCHING ON DIFFERENT TOPICS AROUND THE WORLD
                        <br />
                        WE ARE WORKING ON PROJECTS THAT TRANSCEND THE BOUNDARIES
                    </p>
                </div>
            </div>
            <div className="about-page3-background">
                <div
                    className="about-page3"
                    data-aos={"zoom-in"}
                >
                    <p>IT’S NOT SUPPOSED TO BE A FASHION LABEL<br />
                        ( A LABEL IS AFFIXED TO A PRODUCT OR IT’S PACKAGING )</p>
                    <p>
                        WE INVEST TIME AND EFFORD TO DESIGN A WELL-MADE ARTEFACT
                        <br />
                        THAT FEELS BESPOKE BY PAYING ATTENTION TO DETAIL AND CRAFTSMANSHIP
                    </p>
                    <p>This is MORE THAN CLOTHING</p>
                </div>
            </div>
            <div className="about-page4-background">
                <div
                    className="about-page4"
                    data-aos={"zoom-in"}
                >
                    <p>(REIMAGE YOUR EXPERIENCE)</p>
                    <h4>
                        WE SEEK TO LAUNCH <span>INNOVATIVE PROJECTS</span> WITH CREATIVES AROUND THE WORLD
                        <br />
                        IF YOU ARE INTERESTED IN OUR WORKS PLEASE <span>DROP US A MESSAGE</span>
                    </h4>
                    <p></p>
                </div>
            </div>
            <div
                className='about-footer-background'
            >
                <div className="scroll-container">
                    <div className="scroll-track">
                        WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-
                    </div>
                </div>
                <div className='about-footer'>
                    <h1>CATCH UP</h1>
                    <p>(<span>WINDOW TO ENTER</span>)</p>
                    <div className="line" />
                    <div className="about-footer-social-media">
                        <p><a href='https://www.instagram.com/bui_studios/' target="_blank" rel="noreferrer">INSTAGRAM</a></p>
                        <p><a href='https://www.twitter.com' target="_blank" rel="noreferrer">TWITTER</a></p>
                    </div>
                    <div className="about-footer-link">
                        <p><a href="/terms">LEGAL TERMS</a></p>
                        <p><a href="/info">GENERAL INFO</a></p>
                        <p><a href="/privacy">PRIVACY</a></p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default About;