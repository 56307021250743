import './Privacy.css';

function Privacy() {

    return (
        <div className="privacy">
            <div className="privacy-header">
                <h1>Privacy Policy</h1>
                <div className="line" />
            </div>
            <div className="privacy-content">
                <p><span>PROTECTING YOUR PRIVACY</span></p>
                <p>With the following data protection declaration, we would like to inform you about the types
                    of your personal data (hereinaÿer also referred to as "data") that we process, for what
                    purposes and to what extent in the context of providing our application.
                    The terms used are not gender-specific.
                    Status: September 6, 2022</p>
                <p><span>RELEVANT LEGAL BASES</span></p>
                <p>The following is an overview of the legal basis of the GDPR on the basis of which we
                    process personal data. Please note that in addition to the provisions of the GDPR, national
                    data protection regulations may apply in your or our country of residence or domicile.
                    Furthermore, should more specific legal bases be relevant in individual cases, we will
                    inform you of these in the data protection declaration.
                    Consent (Art. 6 (1) p. 1 lit. a) DSGVO)
                    The data subject has given his/her consent to the processing of personal data relating to
                    him/her for a specific purpose or purposes.
                    Contractual performance and pre-contractual requests (Art. 6 (1) p. 1 lit. b) DSGVO
                    Processing is necessary for the performance of a contract to which the data subject is
                    party or for the performance of pre-contractual measures carried out at the data subject's
                    request.
                    Legal obligation (Art. 6 (1) p. 1 lit. c) DSGVO)
                    Processing is necessary for compliance with a legal obligation to which the controller is
                    subject.
                    Registered interests (Art. 6 (1) p. 1 lit. f) DSGVO)
                    Processing is necessary for the purposes of protecting the legitimate interests of the
                    controller or a third party, except where such interests are overridden by the interests or
                    fundamental rights and freedoms of the data subject which require the protection of
                    personal data.
                    In addition to the data protection regulations of the General Data Protection Regulation,
                    national regulations on data protection apply in Germany. These include, in particular, the
                    Act on Protection against Misuse of Personal Data in Data Processing (Federal Data
                    Protection Act - BDSG). In particular, the BDSG contains special regulations on the right to
                    information, the right to erasure, the right to object, the processing of special categories of
                    personal data, processing for other purposes and transmission, as well as automated
                    decision-making in individual cases, including profiling. Furthermore, it regulates data
                    processing for employment purposes (Section 26 BDSG), in particular with regard to the
                    establishment, implementation or termination of employment relationships as well as the
                    consent of employees. Furthermore, state data protection laws of the individual federal
                    states may apply.</p>
                <p><span>SECURITY MEASURES</span></p>
                <p>We take appropriate technical and organizational measures in accordance with the legal
                    requirements, taking into account the state of the art, the implementation costs and the
                    nature, scope, circumstances and purposes of the processing, as well as the different
                    probabilities of occurrence and the extent of the threat to the rights and freedoms of
                    natural persons, in order to ensure a level of protection appropriate to the risk.
                    The measures include, in particular, safeguarding the confidentiality, integrity and
                    availability of data by controlling physical and electronic access to the data as well as
                    access to, input of, disclosure of, assurance of availability of and segregation of the data.
                    Furthermore, we have established procedures to ensure the exercise of data subjects'
                    rights, the deletion of data, and responses to data compromise. Furthermore, we already
                    take the protection of personal data into account during the development or selection of
                    hardware, soÿware as well as procedures in accordance with the principle of data
                    protection, through technology design and through data protection-friendly default
                    settings.
                    TLS encryption (https): To protect your data transmitted via our online offer, we use TLS
                    encryption. You can recognize such encrypted connections by the prefix https:// in the
                    address bar of your browser.</p>
                <p><span>DATA PROCESSING IN THIRD COUNTRIES</span></p>
                <p>If we process data in a third country (i.e., outside the European Union (EU), the European
                    Economic Area (EEA)) or the processing takes place in the context of the use of third-party
                    services or the disclosure or transfer of data to other persons, entities or companies, this is
                    only done in accordance with the legal requirements.
                    Subject to express consent or contractually or legally required transfer, we process or allow
                    the processing of data only in third countries with a recognized level of data protection,
                    contractual obligation by so-called standard protection clauses of the EU Commission, in
                    the presence of certifications or binding internal data protection regulations (Art. 44 to 49
                    DSGVO, information page of the EU Commission.</p>
                <p><span>USE OF COOKIES</span></p>
                <p>Cookies are small text files or other memory tags that store information on end devices
                    and read information from the end devices. For example, to store the login status in a user
                    account, a shopping cart content in an e-shop, the content accessed or functions used of
                    an online offer. Cookies can also be used for various purposes, e.g. for purposes of
                    functionality, security and convenience of online offers as well as the creation of analyses
                    of visitor flows.
                    Duration of storage: With regard to the duration of storage, a distinction is made between
                    the following types of cookies:
                    Temporary cookies (also: session cookies):
                    Temporary cookies are deleted at the latest aÿer a user has leÿ an online offer and closed
                    his end device (e.g. browser or mobile application).
                    Permanent cookies:
                    Permanent cookies remain stored even aÿer the end device is closed. For example, the
                    login status can be saved or preferred content can be displayed directly when the user
                    visits a website again. Likewise, user data collected with the help of cookies can be used
                    for reach measurement. Unless we provide users with explicit information about the type
                    and storage duration of cookies (e.g., as part of obtaining consent), users should assume
                    that cookies are permanent and that the storage period can be up to two years.</p>
                <p><span>Services</span></p>
                <p>We process data of our contractual and business partners, e.g. customers and interested
                    parties (collectively referred to as "contractual partners") in the context of contractual and
                    comparable legal relationships as well as related measures and in the context of
                    communication with contractual partners (or pre-contractual), e.g. to answer inquiries.
                    We process this data in order to fulfill our contractual obligations. This includes, in
                    particular, the obligations to provide the agreed services, any update obligations and
                    remedies in the event of warranty and other service disruptions. In addition, we process
                    the data to protect our rights and for the purpose of administrative tasks associated with
                    these obligations and company organization. Furthermore, we process the data on the
                    basis of our legitimate interests in proper and business management as well as security
                    measures to protect our contractual partners and our business operations from misuse,
                    endangerment of their data, secrets, information and rights (e.g. for the involvement of
                    telecommunications, transport and other auxiliary services as well as subcontractors,
                    banks, tax and legal advisors, payment service providers or tax authorities). Within the
                    framework of applicable law, we only disclose the data of contractual partners to third
                    parties to the extent that this is necessary for the aforementioned purposes or to fulfill legal
                    obligations. Contractual partners will be informed about further forms of processing, e.g.
                    for marketing purposes, within the scope of this data protection declaration.
                    We inform the contractual partners which data is required for the aforementioned
                    purposes before or in the course of data collection, e.g. in online forms, by means of
                    special marking (e.g. colors) or symbols (e.g. asterisks or similar), or in person.
                    We delete the data aÿer expiry of legal warranty and comparable obligations, i.e., in
                    principle aÿer 4 years, unless the data is stored in a customer account, e.g., as long as it
                    must be kept for legal archiving reasons. The statutory retention period is ten years for
                    documents relevant under tax law as well as for commercial books, inventories, opening
                    balances, annual financial statements, the work instructions required to understand these
                    documents and other organizational documents and accounting records, and six years for
                    received commercial and business letters and reproductions of sent commercial and
                    business letters. The period shall commence at the end of the calendar year in which the
                    last entry was made in the book, the inventory, the opening balance sheet, the annual
                    financial statements or the management report was prepared, the commercial or business
                    letter was received or dispatched or the accounting document was created, furthermore
                    the recording was made or the other documents were created.
                    Insofar as we use third-party providers or platforms to provide our services, the terms and
                    conditions and data protection notices of the respective third-party providers or platforms
                    shall apply in the relationship between the users and the providers.</p>
                <p><span>USE OF THIRD PARTY ONLINE PLATFORMS FOR SALES PURPOSES</span></p>
                <p>We offer our services on online platforms operated by other service providers. In this
                    context, the data protection notices of the respective platforms apply in addition to our
                    data protection notices. This applies in particular with regard to the execution of the
                    payment process and the procedures used on the platforms for reach measurement and
                    interest-based marketing.</p>
                <p><span>PAYMENT METHODS</span></p>
                <p>In the context of contractual and other legal relationships, due to legal obligations or
                    otherwise on the basis of our legitimate interests, we offer data subjects efficient and
                    secure payment options and use other service providers for this purpose in addition to
                    banks and credit institutions (collectively, "payment service providers").
                    The data processed by the payment service providers includes inventory data, such as the
                    name and address, bank data, such as account numbers or credit card numbers,
                    passwords, TANs and checksums, as well as the contract, total and recipient-related
                    information. The information is required to carry out the transactions. However, the data
                    entered is only processed by the payment service providers and stored with them. I.e., we
                    do not receive any account or credit card related information, but only information with
                    confirmation or negative information of the payment. Under certain circumstances, the
                    payment service providers transmit the data to credit agencies. The purpose of this
                    transmission is to check identity and creditworthiness. In this regard, we refer to the terms
                    and conditions and data protection information of the payment service providers.
                    For payment transactions, the terms and conditions and data protection notices of the
                    respective payment service providers apply, which can be accessed within the respective
                    websites or transaction applications. We also refer to these for the purpose of further
                    information and assertion of revocation, information and other data subject rights.</p>
                <p><span>PROVISION OF THE ONLINE OFFER AND WEB HOSTING</span></p>
                <p>We process the users' data in order to provide them with our online services. For this
                    purpose, we process the user's IP address, which is necessary to transmit the content and
                    functions of our online services to the user's browser or terminal device.</p>
                <p><span>REGISTRATION, LOGIN AND USER ACCOUNT</span></p>
                <p>Users can create a user account. In the course of registration, users are provided with the
                    required mandatory data and processed for the purpose of providing the user account on
                    the basis of contractual obligation fulfillment. The processed data includes in particular the
                    login information (username, password as well as an e-mail address).
                    In the context of the use of our registration and login functions as well as the use of the
                    user account, we store the IP address and the time of the respective user action. The
                    storage is based on our legitimate interests as well as those of the users in protection
                    against misuse and other unauthorized use. As a matter of principle, this data is not passed
                    on to third parties unless it is necessary for the prosecution of our claims or there is a legal
                    obligation to do so.
                    Users may be informed by e-mail about processes relevant to their user account, such as
                    technical changes.</p>
                <p><span>CONTACT AND REQUEST MANAGEMENT</span></p>
                <p>When contacting us (e.g. via contact form, e-mail, telephone or via social media) as well as
                    in the context of existing user and business relationships, the information of the inquiring
                    persons is processed to the extent necessary to respond to the contact requests and any
                    requested measures.
                    The response to the contact inquiries as well as the management of contact and inquiry
                    data in the context of contractual or pre-contractual relationships is carried out to fulfill our
                    contractual obligations or to respond to (pre)contractual inquiries and otherwise on the
                    basis of legitimate interests in responding to the inquiries and maintaining user or business
                    relationships.</p>
                <p><span>WEB ANALYSIS, MONITORING AND OPTIMIZATION</span></p>
                <p>Web analytics (also referred to as "reach measurement") is used to evaluate the flow of
                    visitors to our online offering and may include behavior, interests or demographic
                    information about visitors, such as age or gender, as pseudonymous values. With the help
                    of reach analysis, we can recognize, for example, at what time our online offer or its
                    functions or content are most frequently used or invite re-use. Likewise, we can understand
                    which areas need optimization.
                    In addition to web analytics, we may also use testing procedures, for example, to test and
                    optimize different versions of our online offering or its components.
                    Unless otherwise stated below, profiles, i.e. data summarized for a usage process, may be
                    created for these purposes and information may be stored in a browser, or in a terminal
                    device, and read from it. The information collected includes, in particular, websites visited
                    and elements used there, as well as technical information such as the browser used, the
                    computer system used, and information on usage times. If users have agreed to the
                    collection of their location data from us or from the providers of the services we use,
                    location data may also be processed.
                    The IP addresses of the users are also stored. However, we use an IP masking procedure
                    (i.e., pseudonymization by shortening the IP address) to protect users. Generally, in the
                    context of web analysis, A/B testing and optimization, no clear data of the users (such as email
                    addresses or names) are stored, but pseudonyms. This means that we, as well as the
                    providers of the soÿware used, do not know the actual identity of the users, but only the</p>
                <p><span>USE OF SOCIAL MEDIA</span></p>
                <p>We would like to point out that user data may be processed outside the European Union.
                    This may result in risks for the users because, for example, it could make it more difficult to
                    enforce the rights of the users.
                    Furthermore, user data is usually processed within social networks for market research and
                    advertising purposes. For example, usage profiles can be created based on the usage
                    behavior and resulting interests of the users. The usage profiles can in turn be used, for
                    example, to place advertisements within and outside the networks that presumably
                    correspond to the interests of the users. For these purposes, cookies are usually stored on
                    the users' computers, in which the usage behavior and interests of the users are stored.
                    Furthermore, data independent of the devices used by the users may also be stored in the
                    usage profiles (especially if the users are members of the respective platforms and are
                    logged in to them).
                    For a detailed presentation of the respective forms of processing and the options to object
                    (opt-out), we refer to the privacy statements and information provided by the operators of
                    the respective networks.
                    In the case of requests for information and the assertion of data subject rights, we also
                    point out that these can be asserted most effectively with the providers. Only the providers
                    have access to the users' data and can take appropriate measures and provide information
                    directly. If you still need help, you can contact us.</p>
                <p><span>USE OF COOKIES</span></p>
                <p>We integrate functional and content elements into our online offer that are obtained from
                    the servers of their respective providers (hereinaÿer referred to as "third-party providers").
                    These can be, for example, graphics, videos or city maps (hereinaÿer uniformly referred to
                    as "content").
                    The integration always requires that the third-party providers of this content process the IP
                    address of the user, since without the IP address they could not send the content to their
                    browser. The IP address is thus required for the presentation of these contents or
                    functions. We strive to use only such content whose respective providers use the IP
                    address only for the delivery of the content. Third-party providers may also use so-called
                    pixel tags (invisible graphics, also known as "web beacons") for statistical or marketing
                    purposes. The "pixel tags" can be used to analyze information such as visitor traffic on the
                    pages of this website. The pseudonymous information may also be stored in cookies on the
                    user's device and may contain, among other things, technical information about the
                    browser and operating system, referring websites, time of visit and other information about
                    the use of our online offer as well as be linked to such information from other sources.</p>
                <p><span>PLUGINS AND EMBEDDED FUNCTIONS AND CONTENT</span></p>
                <p>We integrate functional and content elements into our online offer that are obtained from
                    the servers of their respective providers (hereinaÿer referred to as "third-party providers").
                    These can be, for example, graphics, videos or city maps (hereinaÿer uniformly referred to
                    as "content").
                    The integration always requires that the third-party providers of this content process the IP
                    address of the user, since without the IP address they could not send the content to their
                    browser. The IP address is thus required for the presentation of these contents or
                    functions. We strive to use only such content whose respective providers use the IP
                    address only for the delivery of the content. Third-party providers may also use so-called
                    pixel tags (invisible graphics, also known as "web beacons") for statistical or marketing
                    purposes. The "pixel tags" can be used to analyze information such as visitor traffic on the
                    pages of this website. The pseudonymous information may also be stored in cookies on the
                    user's device and may contain, among other things, technical information about the
                    browser and operating system, referring websites, time of visit and other information about
                    the use of our online offer as well as be linked to such information from other sources.</p>
                <p><span>MODIFICATION AND UPDATE OF THE PRIVACY POLICY</span></p>
                <p>We ask you to regularly inform yourself about the content of our privacy policy. We adapt
                    the data protection declaration as soon as the changes in the data processing carried out
                    by us make this necessary. We will inform you as soon as the changes require an act of
                    cooperation on your part (e.g. consent) or other individual notification.
                    If we provide addresses and contact information of companies and organizations in this
                    privacy statement, please note that the addresses may change over time and please check
                    the information before contacting us.</p>
            </div>
        </div >
    );
}

export default Privacy;