import './Collections.css';

function Collections() {

    return (
        <div className="collections-background">
            <div className="collections-header">
                <p>(VIEW ALL)</p>
                <h1>Collections</h1>
            </div>
            <div className="collections-header-line"></div>
            <div className="collections-body">
                <div className="collections-background">
                    <div className="collections">
                        <a href="/nonconformity">
                            <h1><span>(01)</span>NONCONFORMITY</h1>
                        </a>
                    </div>
                    <div className="collections-header-line2" />
                </div >
            </div>
            <div className="collections-footer">
                <p></p>
                <div className="collections-footer-social-media">
                    <p id="instagram"><a href="https://www.instagram.com/bui_studios/">Instagram</a></p>
                    <p><a href="https://www.twitter.com">TWITTER</a></p>
                </div>
                <p>info@buistudio.com</p>
            </div>
            <div className="collections-footer-link">
                <p><a href="/terms">LEGAL TERMS</a></p>
                <p><a href="/info">GENERAL INFO</a></p>
                <p><a href="/privacy">PRIVACY</a></p>
            </div>
        </div>
    );
}

export default Collections;