import './Shop.css';
import arrow from './down-arrow.svg';

function Shop() {
    return (
        <div className="shop-background">
            <div className="shop">
                <button className="shop-button">BAG<span>(0)</span></button>
                <div className="shop-filter">
                    <p>ALL</p>
                    <img src={arrow} alt="arrow" />
                </div>
                <p className="shop-message">BE PATIENT AND LET NATURE RUN ITS COURSE</p>
            </div>
            <div className="shop-footer">
                <p></p>
                <div className="shop-footer-social-media">
                    <p id="instagram"><a href="https://www.instagram.com/bui_studios/">Instagram</a></p>
                    <p><a href="https://www.twitter.com">TWITTER</a></p>
                </div>
                <p>info@buistudio.com</p>
            </div>
            <div className="shop-footer-link">
                <p><a href="/terms">LEGAL TERMS</a></p>
                <p><a href="/info">GENERAL INFO</a></p>
                <p><a href="/privacy">PRIVACY</a></p>
            </div>
        </div>
    );
}

export default Shop;