import './App.css';
import Home from './Pages/Home/Home.js';
import Collections from './Pages/Collections/Collections.js';
import About from './Pages/About/About.js';
import Shop from './Pages/Shop/Shop.js';
import Nonconformity from './Pages/Nonconformity/Nonconformity.js';
import Info from './Pages/Info/Info.js';
import Terms from './Pages/Terms/Terms.js';
import Privacy from './Pages/Privacy/Privacy.js';

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/collections" element={<Collections />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/nonconformity" element={<Nonconformity />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
