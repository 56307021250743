import { useEffect, useState } from 'react';
import './Sidenav.css';

function Sidenav() {
    let [color, setColor] = useState("black");

    useEffect(() => {
        let path = window.location.pathname;
        if (path === "/" || path === "/nonconformity") {
            setColor("white");
        }
    }, [])

    return (
        <div className="Sidenav">
            <a
                href="/"
                style={{ color: color }}
            >Home</a>
            <a
                href="/collections"
                style={{ color: color }}
            >Collections</a>
            <a
                href="/shop"
                style={{ color: color }}
            >Shop</a>
            <a
                href="/about"
                style={{ color: color }}
            >About</a>

        </div>
    );
}

export default Sidenav;