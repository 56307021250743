import { useEffect } from 'react';
import './Home.css';
import background1 from './1.png';
import background2 from './2.png';
import background3_1 from './3_1.png';
import background3_2 from './3_2.png';
import background4 from './4.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 2000, once: true });
    }, []);

    const handleClick = (path) => {
        navigate(path);
    }

    return (
        <div className="home">
            <div
                className="home-buibuibui-background"
                style={{
                    backgroundImage: `url(${background1})`
                }}
                data-aos={"fade-up"}
                data-aos-duration="500"
            >
                <div
                    className="home-buibuibui"
                >
                    <h1
                        onClick={() => handleClick("/nonconformity")}
                        data-aos={"zoom-in"}
                        data-aos-duration="500"
                    >BUI.BUI.BUI</h1>
                    <p
                        onClick={() => handleClick("/nonconformity")}
                        data-aos={"zoom-in"}
                    >(<span>LATEST PROJECT</span>)</p>
                </div>
                <div className="scroll-button">
                    <a href="#shop">(<span>Scoll in</span>)</a>
                </div>
            </div>
            <div
                className='home-shop-background'
                id="shop"
                style={{ backgroundImage: `url(${background2})` }}
                data-aos={"fade-up"}
                data-aos-duration="500"
                data-aos-anchor-placement="top-bottom"
            >
                <div
                    className='home-shop'
                >
                    <h1
                        onClick={() => handleClick("/shop")}
                        data-aos={"zoom-in"}
                        data-aos-duration="500"
                    >SHOP.</h1>
                    <p
                        onClick={() => handleClick("/shop")}
                        data-aos={"zoom-in"}
                    >(<span>ENTER</span>)</p>
                </div>
            </div>
            <div
                className="home-allCollections-background"
            >
                <div
                    className="home-allCollections-background-image1"
                    data-aos={"fade-right"}
                    data-aos-duration="500"
                    data-aos-anchor-placement="top-bottom"
                    style={{
                        backgroundImage: `url(${background3_1})`
                    }}
                ></div>
                <div
                    className="home-allCollections-background-image2"
                    data-aos={"fade-left"}
                    data-aos-duration="500"
                    data-aos-anchor-placement="top-bottom"
                    style={{
                        backgroundImage: `url(${background3_2})`
                    }}
                ></div>
                <div
                    className="home-allCollections"
                >
                    <h1
                        onClick={() => handleClick("/collections")}
                        data-aos={"zoom-in"}
                        data-aos-duration="1000"
                    >ALL COLLECTIONS</h1>
                    <p
                        onClick={() => handleClick("/collections")}
                        data-aos={"zoom-in"}
                    >(<span>CLICK TO EXPLORE</span>)</p>
                </div>

            </div>
            <div
                className='home-about-background'
                data-aos={"fade-up"}
                data-aos-duration="500"
                data-aos-anchor-placement="top-bottom"
                style={{
                    backgroundImage: `url(${background4})`
                }}
            >
                <div
                    className='home-about'
                >
                    <h1
                        onClick={() => handleClick("/about")}
                        data-aos={"zoom-in"}
                        data-aos-duration="500"
                    >ABOUT</h1>
                    <p
                        onClick={() => handleClick("/about")}
                        data-aos={"zoom-in"}
                    >(<span>SURVEY US</span>)</p>
                </div>
            </div>
            <div
                className='home-footer-background'
                data-aos={"fade-up"}
                data-aos-duration="500"
                data-aos-anchor-placement="top-bottom"
            >
                <div className="scroll-container">
                    <div className="scroll-track">
                        WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-WORKING-WORLDWIDE-
                    </div>
                </div>
                <div
                    className='home-footer'
                >
                    <h1
                        data-aos={"zoom-in"}
                        data-aos-duration="1000"
                    >STAY IN TOUCH</h1>
                    <p
                        data-aos={"zoom-in"}
                    >(<span>WINDOW TO ENTER</span>)</p>
                    <div className="line" />
                    <div className="home-footer-social-media">
                        <p><a href='https://www.instagram.com/bui_studios/' target="_blank" rel="noreferrer">INSTAGRAM</a></p>
                        <p><a href='https://www.twitter.com' target="_blank" rel="noreferrer">TWITTER</a></p>
                    </div>
                    <div className="home-footer-link">
                        <p><a href="/terms">LEGAL TERMS</a></p>
                        <p><a href="/info">GENERAL INFO</a></p>
                        <p><a href="/privacy">PRIVACY</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;